@use '@angular/material' as mat;
@include mat.core();

$my-palette-primary: (
    100: #B46EC2,
    400: #B46EC2,
    700: #B46EC2,
    contrast: (
        400: #ffffff
    )
);
$my-palette-secondary: (
    100: #C85C8D,
    400: #C85C8D,
    700: #C85C8D,
    contrast: (
        400: #ffffff
    )
);

$my-primary: mat.define-palette($my-palette-primary, 400);
$my-accent: mat.define-palette($my-palette-secondary, 400);

$my-theme: mat.define-light-theme((
    color: (
        primary: $my-primary,
        accent: $my-accent,
    )
));

@include mat.all-component-themes($my-theme);

.price-wrapper {
    display: flex;
    flex-flow: nowrap;

    .input-cost {
        width: 65%;

        & .mat-form-field-outline * {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 1px;
        }
    }

    .select-currency {
        width: 35%;

        & .mat-form-field-outline * {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-width: 0;
        }
    }
}

//https://betterprogramming.pub/best-way-to-overwrite-angular-materials-styles-e38dc8b84962
.mat-menu-panel {
    max-width: 500px!important;
    padding: 0!important;
    border-radius: 8px!important;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16)!important;
    background: #ffffff!important;

    .mat-menu-content {
        padding: 0!important;
    }
}
/*
.mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 28px !important;
}*/

/*
.mat-form-field-appearance-outline {
    border-radius: 80px!important;
    border: 1px solid #95A2AD!important;
    background: #ffffff!important;
    padding: 0!important;
    height: 56px!important;
    width: 100%!important;
    box-shadow: none!important;
}
*/

@font-face {
    font-family: ProximaNova;
    src: url('~src/assets/fonts/ProximaNova-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova;
    src: url('~src/assets/fonts/ProximaNova-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova;
    src: url('~src/assets/fonts/ProximaNova-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova;
    src: url('~src/assets/fonts/ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: ProximaNova;
    src: url('~src/assets/fonts/ProximaNova-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Geometria;
    src: url('~src/assets/fonts/Geometria.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Geometria;
    src: url('~src/assets/fonts/Geometria-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Geometria;
    src: url('~src/assets/fonts/Geometria-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

h1 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin: 0;
}

.main-logo {
    text-align: center;
    font-family: Geometria, sans-serif;
    font-size: 1em;
    line-height: 1.125em;
    display: inline-block;

    &_smart {
        font-weight: 700;
        color: #000000;
    }

    &_resident {
        font-weight: 200;
        color: #000000;
    }

    &_subtitle {
        font-size: .5em;
        line-height: 1.75em;
        color: currentColor;
        display: block;
        letter-spacing: .075em;
    }
}

.sr-kit_button.mat-icon-button {
    background: var(--component-gradient);
    box-shadow: var(--component-shadow);
    color: var(--component-color-primary);
}

.btn-container {
    display: flex;
    flex-flow: row;
    gap: 16px;
    margin-top: 12px;
    //max-width: 200px;

    .sr-kit_button {
        border-radius: 100px;
        border: none;
        padding: 13px 26px;
        font-size: 16px;
        line-height: 24px;
        font-family: Geometria, sans-serif;
        letter-spacing: 1px;
        //color: white;
        //background: linear-gradient(96.73deg, #B46EC2 44.73%, #C85C8D 82.47%);
        background: var(--component-gradient);
        box-shadow: var(--component-shadow);
        color: var(--component-color-primary);
        transition: color .2s, background-color .2s;

        width: 200px;

        &.center {
            margin: 0 auto;
        }

        &[disabled] {
            border: none;
            color: #ffffff;
            background: #c9c9c9;
            box-shadow: none;
        }

        &[data-size=m] { // default
            padding: 13px 26px;
            font-size: 16px;
            line-height: 24px;
            width: 200px;
        }

        &[data-size=s] {
            padding: 10px 10px;
            font-size: 14px;
            line-height: 20px;
            width: 100px;
        }

        &[data-size=l] {
            padding: 14px 48px;
            font-size: 14px;
            line-height: 20px;
            width: 300px;
        }

        &[data-size=ss] {
            padding: 10px 10px;
            font-size: 14px;
            line-height: 20px;
            width: 140px;
        }

        &[data-color=primary] {
            background: var(--component-gradient);
            box-shadow: var(--component-shadow);
            color: var(--component-color-primary);
            transition: color .2s, background-color .2s;
        }

        &[data-color=primary]:disabled {
            border: none;
            color: #ffffff;
            background: #c9c9c9;
            box-shadow: none;
        }

        &[data-color=secondary] {
            background: transparent;
            border: 1px solid var(--component-primary);
            color: var(--component-primary);
            transition: border .1s, color .1s;

            .mat-button-focus-overlay {
                background: transparent;
            }
        }

        &[data-color=secondary]:hover {
            background: transparent;
            border: 1px solid var(--component-secondary);
            color: var(--component-secondary);
        }

        &[data-color=secondary]:disabled {
            border: none;
            color: #ffffff;
            background: #c9c9c9;
        }

        &[data-color=statistics] {
            border: 1px solid #8F96A6;
            color: #565656;
            background: transparent;
            border-radius: 4px;
        }

        &[data-color=text] {
            background: transparent;
            border: none;
            color: var(--component-primary);
            transition: border .1s, color .1s;

            .mat-button-focus-overlay {
                background: transparent;
            }
        }
    }
}


.sr-kit_link {
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: var(--component-primary);
    text-decoration: none;
    cursor: pointer;

    &:hover .mat-button-focus-overlay {
        background: transparent;
    }
}

.right-panel_section-title {
    font-size: 16px;
    line-height: 24px;
}

.right-panel_section-heading {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    font-family: ProximaNova, -apple-system, Arial, sans-serif;
    font-weight: 700;
}

hr {
    margin-block: 0;
    margin-inline: 0;
    border-style: solid;
    border-right: none;
    border-left: 0;
    border-bottom: 0;
    border-color: currentColor;
}

.mat-menu-panel.subheader-type-select {
    margin: -36px 0 0 -16px;
    border-radius: 8px;
    width: 192px;

    .mat-menu-content {
        padding: 0;

        .mat-menu-item {
            font-size: 20px;
            font-family: Geometria, sans-serif;
            line-height: 26px;
            color: #d5d5d5;
            font-weight: 400;
            border-bottom: 1px solid #d5d5d5;

            &:last-child {
                border: none;
            }
        }

        .mat-menu-item.active {
            font-weight: 700;
            color: var(--component-primary);
        }
    }
}

.svg-icon-disabled {
    path {
        fill: #C9C9C9;
    }
}

.mat-menu-panel.rectangular-options-menu {
    border-radius: 8px;
    min-height: 36px;

    .menu-item-highlight {
        color: #C24747;
    }

    .menu-item-disabled {
        color: #C9C9C9;
    }

    .mat-menu-content {
        padding: 0;

        .mat-menu-item {
            font-size: 14px;
            font-family: ProximaNova, -apple-system, Arial, sans-serif;
            line-height: 20px;
            color: #000000;
            font-weight: 400;
            height: 36px;

            &.red {
                color: #C24747;
            }

            .mat-icon {
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }
    }
}

.mat-menu-panel.subheader-filters-select {
    border-radius: 8px;
    min-height: 36px;

    .mat-menu-content {
        padding: 0;

        .mat-menu-item {
            font-size: 14px;
            font-family: ProximaNova, -apple-system, Arial, sans-serif;
            line-height: 20px;
            color: #000000;
            font-weight: 400;
            height: 36px;

            .mat-icon {
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }
    }
}

.mat-menu-panel.profile-menu {
    border-radius: 8px;
    min-height: 36px;
    width: 252px;

    .mat-menu-content {
        padding: 0;

        .mat-menu-item {
            font-size: 14px;
            font-family: ProximaNova, -apple-system, Arial, sans-serif;
            line-height: 20px;
            color: #000000;
            font-weight: 400;
            height: 36px;

            .mat-icon {
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }
    }
}

.mat-menu-panel.submenu-menu {
    border-radius: 0;
    background: #565656;
    position: relative;
    margin-left: 132px;
    overflow: visible;
    box-shadow: none;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .mat-menu-content {
        padding: 0;

        .mat-menu-item {
            font-size: 14px;
            line-height: 36px;
            height: 36px;
            font-family: Geometria, sans-serif;
            font-weight: 400;
            color: #ffffff;
            padding: 0 24px;
            width: 100%;

            &.active {
                color: var(--component-primary);
                //box-shadow: inset 4px 0 #565656, inset 8px 0 var(--component-primary);
            }
        }
    }

    &::before {
        content: "";
        background: #565656;
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 32px;
        left: -8px;
        transform: rotate(45deg);
    }
}

.intervention-list {
    margin: 0 -24px;

    &_item {
        padding: 16px 16px 16px 24px;
        border-top: 1px solid #d5d5d5;
        position: relative;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        gap: 8px;

        &.selected {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 8px;
                background: var(--component-primary);
            }
        }

        .category-icon {
            width: 16px;
            min-width: 16px;
            height: 16px;
            font-size: 16px;
            margin-top: 4px;
        }

        .content {
            flex-grow: 1;
        }
    }
}

.content-placeholder {
    max-width: 480px;
    margin: 64px auto 16px;
    padding: 16px;
    background: #f5f5f5;
    color: #353539;
    border-radius: 10px;
    text-align: center;

    .icon {
        font-size: 64px;
        line-height: 1;
        display: block;
        margin-top: 16px;
    }

    .text {
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
        font-family: Geometria, sans-serif;
        font-weight: 600;

        .mobile-tip {
            display: block;
            font-size: 14px;
            line-height: 20px;
            font-family: ProximaNova, -apple-system, Arial, sans-serif;
            font-weight: 400;

            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }
}

sr-right-panel {
    max-width: 300px;
}

.rectangular-list {
    margin-top: 32px;
    display: flex;
    flex-flow: row wrap;
    gap: 16px;

    rectangular-item {
        width: 280px;
    }

    rectangular-add {
        width: 280px;
        height: 170px;
        display: block;
    }
}

.sub-header-stat {
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    font-family: ProximaNova, -apple-system, Arial, sans-serif;
    font-weight: 300;
}

.table-container {
    margin-top: 32px;
    overflow: hidden;
    position: relative;

    .table-heading {
        border-radius: 8px 8px 0 0;
        background: #F9FAFE;
        color: #000;
        font-family: Geometria, sans-serif;
        font-size: 14px;
        line-height: 20px;
        padding: 16px;

        .bold {
            font-weight: 700;
        }
    }

    .table-wrapper {
        overflow: auto;
        width: 100%;

        table {
            min-width: 100%;
            border-spacing: 0;

            th {
                color: #fff;
                padding: 8px 16px;
                background: var(--component-primary);
                font-family: ProximaNova, -apple-system, Arial, sans-serif;
                font-weight: 700;
                font-size: 14px;
                line-height: 32px;
                text-align: left;
                cursor: pointer;
                white-space: nowrap;
                border-right: 1px solid rgba(0, 0, 0, .12);

                &:last-child {
                    border-right: none;
                }

                .mat-icon {
                    vertical-align: middle;

                    &.table-sort-up {
                        transform: rotateZ(180deg);
                    }
                }
            }

            .search-header {
                color: #fff;
                padding: 8px 16px;
                background: white;
                font-family: ProximaNova, -apple-system, Arial, sans-serif;
                font-weight: 700;
                font-size: 14px;
                height: 20px !important;
                text-align: left;
                white-space: nowrap;
                border-right: 1px solid rgba(0, 0, 0, .12);
                overflow: hidden;
            }

            #search-input {
                width: 100%;
                padding: 0 12px;
                height: 40px;
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, .12);
            }

            #search-input:focus { // I think you said you wanted focus as well
                font-size: 13px;
                border: 1px solid #353539;
                outline: none; // add this
                background-color: #ffffff;
            }
            tr {
                background: #F9FAFE;
                &.row-is-selected{
                    font-weight: bold;
                    background-color: lightgray;
                }

            }
            td {
                padding: 8px 16px;
                white-space: nowrap;
                //background: #F9FAFE;
                font-family: ProximaNova, -apple-system, Arial, sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 24px;
                border-bottom: 1px solid rgba(0, 0, 0, .12);
                border-right: 1px solid rgba(0, 0, 0, .12);

                &:last-child {
                    border-right: none;
                }

                &.options-here {
                    font-weight: 600;
                    color: var(--component-primary);
                    position: relative;
                    padding-right: 24px;

                    .mat-icon {
                        color: #95A2AD;
                        position: absolute;
                        top: 12px;
                        right: 0;
                        cursor: pointer;
                    }
                }

                &.highlight {
                    font-weight: 600;
                    color: var(--component-primary);
                }

                &.star-green {
                    span {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .mat-icon {
                            color: #69B250;
                        }
                    }
                }

                &.star-red {
                    span {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .mat-icon {
                            color: #C24747;
                        }
                    }
                }
            }
        }
    }

    &.gray-theme {
        .table-wrapper {
            table {
                th {
                    background: #95A2AD;
                    color: #ffffff;
                    border: none;
                }

                td {
                    border: none;
                    background: transparent;
                }
            }
        }
    }
}

.bold {
    font-weight: 700;
}

.right-panel {
    .pagination {
        margin: 0 auto;

        .mat-icon {
            vertical-align: middle;
        }
    }
}

.mat-menu-panel.poll-answer-menu {
    position: relative;

    .mat-menu-content {
        padding: 0;

        .mat-menu-item {
            font-size: 14px;
            line-height: 24px;
            height: 36px;
            font-family: ProximaNova, -apple-system, Arial, sans-serif;
            font-weight: 400;
            padding: 0 24px;

            .mat-icon {
                width: 18px;
                height: 18px;
                font-size: 18px;
                color: var(--component-primary);
            }
        }
    }
}

.answer-item {
    padding: 16px;
    border-radius: 16px;
    position: relative;
    background: #ffffff;

    &_content {
        display: flex;
        flex-flow: column nowrap;

        .drag-handler {
            position: absolute;
            top: 8px;
            left: calc(50% - 12px);
            cursor: pointer;
            color: var(--component-primary);
            display: none;
        }

        .title {
            margin: 32px 0 16px;
            font-weight: 700;
        }

        .options {
            position: absolute;
            right: 16px;
            top: 38px;
            color: #95A2AD;
            display: none;
        }

        .mat-form-field-suffix .mat-icon-button .mat-icon {
            color: #95A2AD;
        }

        .start-container {
            display: flex;
            flex-flow: row nowrap;
            gap: 8px;

            .mat-icon {
                color: var(--component-primary);
            }
        }

        .img-grid {
            display: flex;
            flex-flow: row wrap;
            gap: 16px;

            .img-item {
                width: 160px;
                height: 240px;
                position: relative;
                background-position: center;
                background-size: cover;
                border-radius: 8px;
                overflow: hidden;
                display: flex;
                justify-content: center;

                img {
                    max-height: 240px;
                }
            }

            .add-img-item {
                width: 160px;
                height: 240px;
                border-radius: 8px;
                box-shadow: 0 0 0 1px var(--component-primary);
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #95A2AD;
                cursor: pointer;

                .add-btn {
                    color: #95A2AD;
                }
            }
        }
    }

    &:hover {
        box-shadow: 0 0 0 1px var(--component-primary);

        .answer-item_content {
            .drag-handler, .options {
                display: unset;
            }
        }
    }

    .placeholder {
        background: rgba(0, 0, 0, .2);
        border: 2px dashed #95A2AD;
    }
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.content.cdk-drop-list-dragging .answer-item {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    box-sizing: border-box;
    border: 1px solid var(--component-primary);
}

.cdk-drag-placeholder {
    opacity: .5;
    background: var(--component-secondary);
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #95A2AD;
}

.double-field-wrapper {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 16px;

    .mat-form-field {
        flex: 1;
    }
}

.tooltip-custom.mat-tooltip {
    background: var(--component-primary);
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
    font-family: ProximaNova, -apple-system, Arial, sans-serif;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .1);
    padding: 8px 16px;
}

.chat {
    margin-top: 32px;
    max-height: 540px;
    border-radius: 15px;
    padding: 0 16px;
    background: #f5f5f5;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    .chat-title {
        margin: 0 -16px;
        padding: 16px;
        z-index: 1;
        position: sticky;
        top: 0;
        background: #F7F5F8;
        font-size: 20px;
        font-weight: 700;
        font-family: ProximaNova, -apple-system, Arial, sans-serif;
        line-height: 26px;
    }

    .no-messages {
        text-align: center;
        color: #95A2AD;
        font-size: 16px;
        line-height: 24px;
        margin: 64px 0;
    }

    .message-item {
        border-radius: 8px;
        background: #EBE9EB;
        color: #000;
        font-size: 14px;
        line-height: 20px;
        margin: .5rem .5rem 20px;
        align-self: flex-start;
        position: relative;
        min-width: 60px;

        .message-date {
            font-size: 10px;
            line-height: 14px;
            white-space: nowrap;
            display: block;
            color: #939393;
            position: absolute;
            bottom: -16px;
            left: 0;
            right: unset;
            text-align: left;
        }

        &.admin {
            background: #EFE0F2;
            align-self: flex-end;

            .message-date {
                right: 0;
                left: unset;
                text-align: right;
            }
        }

        span.message-content {
            padding: .5rem 1rem;
            display: block;
        }

        img.message-content {
            display: block;
        }
    }

    .chat-input-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        gap: 16px;
        position: sticky;
        bottom: 0;
        background: #f5f5f5;
        padding-top: 8px;

        .chat-input {
            flex-grow: 1;
        }

        .send-btn {
            margin-top: 4px;
        }
    }
}

.files-grid {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    margin: 16px 0;

    .file-item {
        position: relative;

        iframe {
            width: 300px;
            height: 480px;
            border: none;
        }

        .clear-btn {
            position: absolute;
            top: 0;
            right: 0;
            opacity: .5;
            background: #95A2AD;

            .mat-icon {
                color: #000;
            }
        }
    }
}

.provider-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 56px 0 8px;

    .left-button, .right-button {
        position: absolute;
        top: 8px;
        cursor: pointer;
    }

    .left-button {
        left: 8px;
    }

    .right-button {
        right: 8px;
    }

    .title {
        font-size: 18px;
        line-height: 26px;
        margin: 0;
        text-align: center;
        padding: 0 8px;
        font-family: Geometria, sans-serif;
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    sr-logo {
        animation: spin 2s ease infinite;

        @keyframes spin {
            0% {
                transform: scale(1)
            }
            20% {
                transform: scale(1.2)
            }
            40% {
                transform: scale(1)
            }
            60% {
                transform: scale(1.4)
            }
            80% {
                transform: scale(1.2)
            }
            100% {
                transform: scale(1)
            }
        }
    }
}

.see-application-dialog {
    margin: 24px;
    position: relative;

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
    }

    .heading {
        margin-top: 16px;
        //color: #69B250;


    }

    .btn-line {
        display: flex;
        justify-content: end;
    }

}

.dialog-buttons-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 16px;
    align-items: center;
}

.custom-option-item {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background: transparent;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 24px;
    cursor: pointer;
    transition: background-color .1s linear;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &:hover {
        background: rgba(0, 0, 0, .06);
    }
}

.input-info-wrapper {
    display: flex;
    gap: 16px;
    flex-flow: row nowrap;

    .mat-form-field {
        width: 100%;
    }

    .mat-icon {
        color: #979EAB;
        margin: 20px 0;
    }
}

.application-banner-grid {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d5d5d5;

    .no-data {
        font-size: 16px;
        line-height: 20px;
        color: #565656;
        padding: 16px 0;
        font-family: Geometria, sans-serif;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
}

.table-size {
    max-width: 1400px;
}

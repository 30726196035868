@use 'reseter.css/src/scss/reseter';
@use 'styles/material';
@use 'styles/variables';
@use 'styles/fonts';
@use 'styles/components';
@import url('jsoneditor/dist/jsoneditor.min.css');
@import 'intl-tel-input/build/css/intlTelInput.css';
html {
    font-family: ProximaNova, -apple-system, Arial, sans-serif;
    font-size: 16px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    //overflow-x: hidden;
    //overscroll-behavior-x: none;
}

.highlight-text {
    color: var(--component-primary);
}

.bold {
    font-weight: 700;
}

.cursor {
    cursor: pointer;
}

@media screen and (min-width: 1280px) {
    sr-header {
        display: none;
    }

    .main-wrapper {
        padding: 24px;
        flex-grow: 1;

        .content {
        }
    }
}

.jsoneditor {
    border-color: var(--component-primary);
}

.jsoneditor-menu {
    border-color: var(--component-primary);
    background-color: var(--component-primary);
}


.provider-home-body {
    padding-left: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 1000px;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}
//::-webkit-scrollbar {
//    display: none;
//    width: 0;  /* Remove scrollbar space */
//    background: transparent;  /* Optional: just make scrollbar invisible */
//}
///* Optional: show position indicator in red */
//::-webkit-scrollbar-thumb {
//    background: #FF0000;
//}

/*
@media only screen and (max-width: 450px) {
    !* Your scrollbar hiding styles here *!
    //body {
    //    overflow-x: hidden;
    //    overflow-y: hidden;
    //}

    ::-webkit-scrollbar {
        display: none;
        width: 0;  !* Remove scrollbar space *!
        background: transparent;  !* Optional: just make scrollbar invisible *!
    }

}
*/

.help-text-sub-header {
    display: flex;
    flex-direction: row;
}
